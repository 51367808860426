import request from '@/utils/request'
// 获取用户详细信息
export function getInfo(data) {
    return request({
        url: '/member/memberInfo',
        method: 'get',
        params: data,
    })
};
// 获取用户详细信息
export function getbiao(data) {
    return request({
        url: '/member/statistics',
        method: 'get',
        params: data,
    })
}
